import React, { Suspense, useEffect, useRef } from "react";
import { Hero, BlogResult, HomeResult, Loader } from "../components";
import { fetchData } from "../services/fetchdata";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomeInfo from "./HomeInfo";

// .home-bg {
//   background-image: url('assets/img/home-header.jpg')  /* default */
// }

// .webp .home-bg {
//   background-image: url(assets/img/home-header.webp')
// }
function Home() {
  const homeResultRef = useRef(null);
  const [searchParams] = useSearchParams();

  const q = searchParams.get("q");
  const handleClickScroll = () => {
    homeResultRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (q !== null) handleClickScroll();
  }, [q]);

  return (
    <main>
      <Helmet>
        <title>Recommend Me Something</title>
        {/* <link rel="canonical" href="https://www.recommendmesomething.com" /> */}

        <meta
          name="description"
          content="RecommendMeSomething believes that discovering the perfect movie, book, or music should be a delightful experience. This
            innovative tool is designed to make the entertainment selection process a breeze, offering personalized
            recommendations tailored to unique preferences."
        />
        <meta
          name="keywords"
          content="movie recommendation, book recommendation, suggest me movies, suggest me books, books, movies, recommendation, recommend me something, recommendmesomething"
        />
        {/* <meta name="author" content={postDetail.author.name} /> */}
        <meta property="og:title" content="Recommend Me Something" />
        <meta
          property="og:description"
          content="RecommendMeSomething believes that discovering the perfect movie, book, or music should be a delightful experience. This
            innovative tool is designed to make the entertainment selection process a breeze, offering personalized
            recommendations tailored to unique preferences."
        />
        {/* <meta property="og:image" content={postDetail.seoOverride.image?.url} /> */}
        {/* <meta property="og:url" content="https://example.com/my-page" /> */}
        <meta name="twitter:title" content="Recommend Me Something" />
        <meta
          name="twitter:description"
          content="RecommendMeSomething believes that discovering the perfect movie, book, or music should be a delightful experience. This
            innovative tool is designed to make the entertainment selection process a breeze, offering personalized
            recommendations tailored to unique preferences."
        />
        {/* <meta name="twitter:image" content={postDetail.seoOverride.image.url} /> */}
        {/* <meta name="twitter:card" content="summary_large_image" /> */}
      </Helmet>
      <Hero page={"Home"} howto={"to watch!"} herobg={"url('./img/books2.webp')"} scrollToResult={handleClickScroll} />
      {/* Result displayed after search */}
      <section ref={homeResultRef}>
        <Suspense fallback={<Loader />}>
          {q !== null && (
            <HomeResult
              query={q}
              movieResource={fetchData(
                `https://web-production-e62e.up.railway.app/movies/suggest-many?q=${encodeURIComponent(q)}`
              )}
              bookResource={fetchData(
                `https://web-production-e62e.up.railway.app/books/suggest-many?q=${encodeURIComponent(q)}`
              )}
            />
          )}
        </Suspense>
      </section>

      {/* home page info */}
      <HomeInfo />
      {/* blog of all categories */}
      <BlogResult />
    </main>
  );
}

export default Home;
