import React, { Suspense, useEffect, useRef } from "react";
import { Hero, MovieResult, BlogResult, Loader } from "../components";
import { fetchData } from "../services/fetchdata";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Movies = () => {
  const movieResultRef = useRef(null);
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  const handleClickScroll = () => {
    movieResultRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (q !== null) handleClickScroll();
  }, [q]);

  return (
    <main>
      <Helmet>
        <title>Find personalized movie recommendations - Recommendmesomething</title>
        <meta
          name="description"
          content="Discover the best movies to watch with Recommendmesomething, your ultimate movie suggestion companion. Our recommendation engine goes beyond generic suggestions, ensuring personalized movie suggestions for your unique preferences. Find good movies across genres, get movie suggestions on Amazon Prime, Netflix, Disney etc and explore curated picks for an exceptional viewing experience."
        />
        <meta
          name="keywords"
          content="movie recommendation, book recommendation, suggest me movies, suggest me books, books, movies, recommendation, recommend me something, recommendmesomething"
        />
        {/* <meta name="author" content={postDetail.author.name} /> */}
        <meta property="og:title" content="Find personalized movie recommendations - Recommendmesomething" />
        <meta
          property="og:description"
          content="Discover the best movies to watch with Recommendmesomething, your ultimate movie suggestion companion. Our recommendation engine goes beyond generic suggestions, ensuring personalized movie suggestions for your unique preferences. Find good movies across genres, get movie suggestions on Amazon Prime, Netflix, Disney etc and explore curated picks for an exceptional viewing experience."
        />
        {/* <meta property="og:image" content={postDetail.seoOverride.image?.url} /> */}
        {/* <meta property="og:url" content="https://example.com/my-page" /> */}
        <meta name="twitter:title" content="Find personalized movie recommendations - Recommendmesomething" />
        <meta
          name="twitter:description"
          content="Discover the best movies to watch with Recommendmesomething, your ultimate movie suggestion companion. Our recommendation engine goes beyond generic suggestions, ensuring personalized movie suggestions for your unique preferences. Find good movies across genres, get movie suggestions on Amazon Prime, Netflix, Disney etc and explore curated picks for an exceptional viewing experience."
        />
        {/* <meta name="twitter:image" content={postDetail.seoOverride.image.url} /> */}
        {/* <meta name="twitter:card" content="summary_large_image" /> */}
      </Helmet>
      <Hero
        page={"Movies"}
        howto={" to watch"}
        herobg={"url('./img/moviesbg.webp')"}
        scrollToResult={handleClickScroll}
      />

      {/* Show Result after search */}
      <section ref={movieResultRef}>
        <Suspense fallback={<Loader />}>
          {q !== null && (
            <MovieResult
              query={q}
              resource={fetchData(
                `https://web-production-e62e.up.railway.app/movies/suggest-many?q=${encodeURIComponent(q)}`
              )}
            />
          )}
        </Suspense>
      </section>

      {/* 2nd page */}
      {/* movies blog */}
      <BlogResult type={"Movies"} />
    </main>
  );
};

export default Movies;
