import React from "react";
import { Helmet } from "react-helmet";

function Terms() {
  return (
    <main className="w-full bg-[#EEF0F2] pt-12 py-10  ">
      <Helmet>
        <title>Terms and Conditions - recommendmesomething</title>
        <meta
          name="description"
          content="Terms and Conditions provide guidelines for usage, privacy, and user rights. Navigate through this essential document to understand our commitment to transparency, security, and a seamless user experience. Your trust is our priority, and these terms outline the foundation of our relationship with you."
        />
        <meta
          name="keywords"
          content="terms, condition, terms and condition, terms & condition, recommend me something, recommendmesomething"
        />
        {/* <meta name="author" content={postDetail.author.name} /> */}
        <meta property="og:title" content="Terms and Conditions - recommendmesomething" />
        <meta
          property="og:description"
          content="Terms and Conditions provide guidelines for usage, privacy, and user rights. Navigate through this essential document to understand our commitment to transparency, security, and a seamless user experience. Your trust is our priority, and these terms outline the foundation of our relationship with you."
        />
        {/* <meta property="og:image" content={postDetail.seoOverride.image?.url} /> */}
        {/* <meta property="og:url" content="https://example.com/my-page" /> */}
        <meta name="twitter:title" content="Terms and Conditions - recommendmesomething" />
        <meta
          name="twitter:description"
          content="Terms and Conditions provide guidelines for usage, privacy, and user rights. Navigate through this essential document to understand our commitment to transparency, security, and a seamless user experience. Your trust is our priority, and these terms outline the foundation of our relationship with you."
        />
        {/* <meta name="twitter:image" content={postDetail.seoOverride.image.url} /> */}
        {/* <meta name="twitter:card" content="summary_large_image" /> */}
      </Helmet>
      <div className="items-start m-auto w-11/12 sm:w-5/6 lg:w-7/12  justify-start flex bg-white rounded-lg p-6 md:p-12 sm:p-16">
        <div className="container my-8 text-left text-lg gap-4 flex flex-col text-gray-600">
          <h1 className="my-8 font-extrabold text-4xl text-center text-sky-900 ">Terms & Conditions</h1>

          <p className=" text-xl italic font-medium">
            Welcome to <a href="https://recommendmesomething.com/">Recommend Me Something</a> !
          </p>

          <p>
            These terms and conditions outline the rules and regulations for the use of Recommend Me Something's
            Website, located at
            <a className="hover:text-sky-700 hover:underline" href="https://recommendmesomething.com/">
              {" "}
              https://recommendmesomething.com/.
            </a>
          </p>

          <p>
            By accessing this website we assume you accept these terms and conditions. Do not continue to use Recommend
            Me Something if you do not agree to take all of the terms and conditions stated on this page.
          </p>

          <p>
            The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and
            all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to
            the Company's terms and conditions. "The Company", "Recommend Me Something", "Ourselves", "We", "Our" and
            "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms
            refer to the offer, acceptance and consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's
            needs in respect of provision of the Company's stated services, in accordance with and subject to,
            prevailing law of in. Any use of the above terminology or other words in the singular, plural,
            capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
          </p>

          <h2 className="text-xl text-gray-950 font-bold my-6">
            <strong>Cookies</strong>
          </h2>

          <p>
            We employ the use of cookies. By accessing Recommend Me Something, you agreed to use cookies in agreement
            with the Recom Ai's Privacy Policy.
          </p>

          <p>
            Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used
            by our website to enable the functionality of certain areas to make it easier for people visiting our
            website. Some of our affiliate/advertising partners may also use cookies.
          </p>

          <h2 className="text-xl text-gray-950 font-bold my-6">
            <strong>License</strong>
          </h2>

          <p>
            Unless otherwise stated, Recommend Me Something and/or its licensors own the intellectual property rights
            for all material on Recommend Me Something. All intellectual property rights are reserved. You may access
            this from Recommend Me Something for your own personal use subjected to restrictions set in these terms and
            conditions.
          </p>

          <p>You must not:</p>
          <ul className="list-disc md:px-14 px-4 ">
            <li>Republish material from Recommend Me Something</li>
            <li>Sell, rent or sub-license material from Recommend Me Something</li>
            <li>Reproduce, duplicate or copy material from Recommend Me Something</li>
            <li>Redistribute content from Recommend Me Something</li>
          </ul>

          <p>
            This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the
            <a href="https://www.termsandconditionsgenerator.com/">Free Terms and Conditions Generator</a>.
          </p>

          <p>
            Parts of this website offer an opportunity for users to post and exchange opinions and information in
            certain areas of the website. Recommend Me Something does not filter, edit, publish or review Comments prior
            to their presence on the website. Comments do not reflect the views and opinions of Recommend Me
            Something,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their
            views and opinions. To the extent permitted by applicable laws, Recommend Me Something shall not be liable
            for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of
            and/or posting of and/or appearance of the Comments on this website.
          </p>

          <p>
            Recommend Me Something reserves the right to monitor all Comments and to remove any Comments which can be
            considered inappropriate, offensive or causes breach of these Terms and Conditions.
          </p>

          <p>You warrant and represent that:</p>

          <ul className="list-disc md:px-14 px-4 ">
            <li>
              You are entitled to post the Comments on our website and have all necessary licenses and consents to do
              so;
            </li>
            <li>
              The Comments do not invade any intellectual property right, including without limitation copyright, patent
              or trademark of any third party;
            </li>
            <li>
              The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material
              which is an invasion of privacy
            </li>
            <li>
              The Comments will not be used to solicit or promote business or custom or present commercial activities or
              unlawful activity.
            </li>
          </ul>

          <p>
            You hereby grant Recommend Me Something a non-exclusive license to use, reproduce, edit and authorize others
            to use, reproduce and edit any of your Comments in any and all forms, formats or media.
          </p>

          <h2 className="text-xl font-bold my-6 text-gray-950">
            <strong>Hyperlinking to our Content</strong>
          </h2>

          <p>The following organizations may link to our Website without prior written approval:</p>

          <ul className="list-disc md:px-14 px-4 ">
            <li>Government agencies;</li>
            <li>Search engines;</li>
            <li>News organizations;</li>
            <li>
              Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites
              of other listed businesses; and
            </li>
            <li>
              System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and
              charity fundraising groups which may not hyperlink to our Web site.
            </li>
          </ul>

          <p>
            These organizations may link to our home page, to publications or to other Website information so long as
            the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval
            of the linking party and its products and/or services; and (c) fits within the context of the linking
            party's site.
          </p>

          <p>We may consider and approve other link requests from the following types of organizations:</p>

          <ul className="list-disc md:px-14 px-4 ">
            <li>commonly-known consumer and/or business information sources;</li>
            <li>dot.com community sites;</li>
            <li>associations or other groups representing charities;</li>
            <li>online directory distributors;</li>
            <li>internet portals;</li>
            <li>accounting, law and consulting firms; and</li>
            <li>educational institutions and trade associations.</li>
          </ul>

          <p>
            We will approve link requests from these organizations if we decide that: (a) the link would not make us
            look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any
            negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence
            of Recommend Me Something; and (d) the link is in the context of general resource information.
          </p>

          <p>
            These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does
            not falsely imply sponsorship, endorsement or approval of the linking party and its products or services;
            and (c) fits within the context of the linking party's site.
          </p>

          <p>
            If you are one of the organizations listed in paragraph 2 above and are interested in linking to our
            website, you must inform us by sending an e-mail to Recommend Me Something. Please include your name, your
            organization name, contact information as well as the URL of your site, a list of any URLs from which you
            intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3
            weeks for a response.
          </p>

          <p>Approved organizations may hyperlink to our Website as follows:</p>

          <ul className="list-disc md:px-14 px-4 ">
            <li>By use of our corporate name; or</li>
            <li>By use of the uniform resource locator being linked to; or</li>
            <li>
              By use of any other description of our Website being linked to that makes sense within the context and
              format of content on the linking party's site.
            </li>
          </ul>

          <p>
            No use of Recommend Me Something's logo or other artwork will be allowed for linking absent a trademark
            license agreement.
          </p>

          <h2 className="text-xl font-bold my-6 text-gray-950">
            <strong>iFrames</strong>
          </h2>

          <p>
            Without prior approval and written permission, you may not create frames around our Webpages that alter in
            any way the visual presentation or appearance of our Website.
          </p>

          <h2 className="text-gray-950 text-xl font-bold my-6">
            <strong>Content Liability</strong>
          </h2>

          <p>
            We shall not be hold responsible for any content that appears on your Website. You agree to protect and
            defend us against all claims that is rising on your Website. No link(s) should appear on any Website that
            may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates
            the infringement or other violation of, any third party rights.
          </p>

          <h2 className="text-gray-950 text-xl font-bold my-6">
            <strong>Reservation of Rights</strong>
          </h2>

          <p>
            We reserve the right to request that you remove all links or any particular link to our Website. You approve
            to immediately remove all links to our Website upon request. We also reserve the right to amen these terms
            and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be
            bound to and follow these linking terms and conditions.
          </p>

          <h2 className="text-gray-950 text-xl font-bold my-6">
            <strong>Removal of links from our website</strong>
          </h2>

          <p>
            If you find any link on our Website that is offensive for any reason, you are free to contact and inform us
            any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you
            directly.
          </p>

          <p>
            We do not ensure that the information on this website is correct, we do not warrant its completeness or
            accuracy; nor do we promise to ensure that the website remains available or that the material on the website
            is kept up to date.
          </p>

          <h2 className="text-gray-950 text-xl font-bold my-6">
            <strong>Disclaimer</strong>
          </h2>

          <p>
            To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions
            relating to our website and the use of this website. Nothing in this disclaimer will:
          </p>

          <ul className="list-disc md:px-14 px-4 ">
            <li>limit or exclude our or your liability for death or personal injury;</li>
            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
          </ul>

          <p>
            The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are
            subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including
            liabilities arising in contract, in tort and for breach of statutory duty.
          </p>

          <p>
            As long as the website and the information and services on the website are provided free of charge, we will
            not be liable for any loss or damage of any nature.
          </p>
        </div>
      </div>
    </main>
  );
}

export default Terms;
