import React from "react";
import { MovieItem } from "../components";

function MovieResult({ query, resource }) {
  const data = resource?.read();
  console.log(data);

  return (
    <div>
      {data && data.length > 0 && (
        <div className="bg-white mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <h2 className="font-bold text-4xl  left-0 text-left mx-2 mb-14">Showing movies recomendation for: {query}</h2>
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {data?.map((moviedata) => {
              return <MovieItem key={moviedata.id} data={moviedata} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default MovieResult;
