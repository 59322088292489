import React from "react";
// import { FaFilm, FaBook, FaPodcast, FaTv, FaMusic, FaGamepad } from "react-icons/fa";

const HomeInfo = () => {
  return (
    <section id="homeInfo" className="bg-gradient-to-b from-blue-50 to-blue-100 py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-5xl font-bold text-sky-900 mb-6">Your Personalized Gateway to Entertainment</h2>
        <p className="text-xl text-slate-800 mb-12">
          Explore tailored recommendations for movies, series, anime, books, podcasts, and more, powered by advanced AI.
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
          <div className="bg-white shadow-2xl p-8 rounded-lg hover:shadow-xl transition-shadow duration-300">
            <h4 className="text-3xl font-bold text-sky-900 mb-6">Why Choose Us?</h4>
            <ul className="list-disc list-inside text-left text-gray-800 space-y-4">
              <li>Personalized Experience: Get suggestions that match your unique tastes and preferences.</li>
              <li>
                Comprehensive Library: Access a vast collection of entertainment options across various categories.
              </li>
              <li>Cutting-Edge AI: Benefit from the latest advancements in AI for accurate recommendations.</li>
              <li>Community Reviews: Read in-depth reviews from our vibrant community.</li>
              <li>User-Friendly Design: Navigate seamlessly with our intuitive interface.</li>
            </ul>
          </div>
          <div className="bg-white shadow-2xl p-8 rounded-lg hover:shadow-xl transition-shadow duration-300">
            <h4 className="text-3xl font-bold text-sky-900 mb-6">Features</h4>
            <ul className="list-disc list-inside text-left text-gray-800 space-y-4">
              <li>AI-Powered Recommendations: Enjoy personalized content suggestions.</li>
              <li>Trending Now: Stay updated with the latest trends in entertainment.</li>
              <li>Community Insights: Engage with detailed reviews and ratings.</li>
              <li>Diverse Content: Find something for every mood and interest.</li>
              <li>Easy Navigation: Experience a smooth and intuitive user interface.</li>
            </ul>
          </div>
        </div>

        {/* <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
          <div className="text-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaFilm className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-blue-700 mb-2">Movies</h3>
            <p className="text-blue-600">Find the perfect film for every mood.</p>
          </div>
          <div className="text-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaTv className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-blue-700 mb-2">Series</h3>
            <p className="text-blue-600">Binge-watch the best series out there.</p>
          </div>
          <div className="text-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaBook className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-blue-700 mb-2">Books</h3>
            <p className="text-blue-600">Dive into our collection of bestselling books.</p>
          </div>
          <div className="text-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaPodcast className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-blue-700 mb-2">Podcasts</h3>
            <p className="text-blue-600">Listen to popular podcasts.</p>
          </div>
          <div className="text-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaMusic className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-blue-700 mb-2">Music</h3>
            <p className="text-blue-600">Explore our vast music library.</p>
          </div>
          <div className="text-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaGamepad className="w-12 h-12 text-blue-600 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-blue-700 mb-2">Games</h3>
            <p className="text-blue-600">Find the best games to play.</p>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default HomeInfo;
